import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { type JwtPayload, jwtDecode } from "jwt-decode";

import instance, { api } from "@/services/instance";
import { type RootState } from "@/store/hooks";
import type { User } from "@/store/v2/currentUser/selectors";

interface AuthState {
  accessToken: string;
  readonly status: "idle" | "loading" | "success" | "error" | "expired";
  user: User;
}

export const getAuthorization = createAsyncThunk(
  "auth/getAuthorization",
  async (
    { authenticationToken, propertyId = undefined }: { authenticationToken: string; propertyId?: number },
    { dispatch, rejectWithValue },
  ) => {
    const propertyPath = propertyId ? `/${propertyId}` : "";
    const ttl = jwtDecode(authenticationToken).exp! * 1000;

    try {
      if (ttl < Date.now()) {
        throw new Error("token_expired");
      }

      const response = await instance(`/api/v2/authorization${propertyPath}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authenticationToken}`,
        },
      });

      if (response.status >= 400) {
        throw new Error(`Failed to get authorization: ${response.statusText}`);
      }

      const accessToken = ((await response.data) as { token: string }).token;

      dispatch(authSlice.actions.setAccessToken(accessToken));

      return accessToken;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAuthorizedUser = createAsyncThunk(
  "auth/getAuthorizedUser",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const { auth } = getState() as RootState;
      if (!auth?.user?.id || !auth?.accessToken) {
        throw new Error("User ID or access token not found");
      }

      const response = await api(`/api/v2/users/${auth?.user.id}`, {
        method: "GET",
      });

      if (response.status >= 400) {
        throw new Error(`Failed to fetch user: ${response.statusText}`);
      }
      const user = (await response.data) as User;
      dispatch(setUser(user));

      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: "",
    status: "idle",
    user: {} as User,
  } satisfies AuthState as AuthState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = { ...state.user, ...action.payload };
    },
    setStatus: (state, action: PayloadAction<"idle" | "loading" | "success" | "error" | "expired">) => {
      state.status = action.payload;
      if (action.payload === "expired" || action.payload === "error") {
        state.accessToken = "";
        state.user = {} as User;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthorization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAuthorization.fulfilled, (state, action) => {
        const { user } = jwtDecode<JwtPayload & { user: User }>(action.payload);
        state.user = { ...state.user, ...user };
      })
      .addCase(getAuthorization.rejected, (state, action) => {
        if (action.payload && (action.payload as Error).message === "token_expired") {
          state.status = "expired";
        } else {
          state.status = "error";
        }
        state.accessToken = "";
        state.user = {} as User;
      })
      .addCase(getAuthorizedUser.fulfilled, (state) => {
        state.status = "success";
      });
  },
});

export const { setUser, setStatus } = authSlice.actions;

export default authSlice.reducer;

export const selectUser = (state: RootState) => state.auth?.user;
export const selectAuthStatus = (state: RootState) => state.auth?.status;
