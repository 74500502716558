import { lazy } from "react";
import type { RouteObject } from "react-router-dom";

import { ADMIN_ROUTE, DASHBOARD_ROUTE, ORGANIZATIONS, PUBLIC_ROUTE, VISUAL_BUILDER_ROUTE } from "./constants";
import { currentOrganizationLoader } from "./loaders/currentOrganizationLoader";
import { experiencesLoader } from "./loaders/experiencesLoader";
import { AuthenticationGuard } from "../components/AuthenticationGuard";
import {
  AnalyticsLayout,
  Auth0ProviderLayout,
  DashboardLayout,
  DropZoneLayout,
  RootLayout,
  VisualBuilderLayout,
  analyticsLoader,
  dashboardLoader,
  shouldRevalidateAnalytics,
} from "../layouts";
import { visualBuilderLoader } from "./loaders/visualBuilderLoader";

const AnalyticsOverviewCard = lazy(() => import("../Dashboard/pages/AnalyticsOverview/AnalyticsDetailedPage"));
const AsyncAccounts = lazy(() => import("../Dashboard/pages/Accounts/Accounts"));
const AsyncActivateAccountPage = lazy(
  () => import("../Dashboard/pages/Accounts/components/ManageAccount/ManageAccountForm"),
);
const AsyncManageAccountPage = lazy(
  () => import("../Dashboard/pages/Accounts/components/ManageAccount/ManageAccountForm"),
);
const AsyncAllUsers = lazy(() => import("../Dashboard/pages/Users/Users"));
const AsyncAnalyticsOverview = lazy(() => import("../Dashboard/pages/AnalyticsOverview/AnalyticsOverviewPage"));
const AsyncAssetLibrary = lazy(() => import("../Dashboard/pages/AssetLibrary/AssetLibrary"));
const AsyncExperienceFeatureFlagWrapper = lazy(
  () => import("../Dashboard/pages/ExperiencesV2/ExperiencesV2FeatureFlagWrapper"),
);
const AsyncComingSoon = lazy(() => import("../Dashboard/pages/ComingSoon/ComingSoon"));
const AsyncContactDetails = lazy(() => import("../Dashboard/pages/Contacts/ContactDetails"));
const AsyncContacts = lazy(() => import("../Dashboard/pages/Contacts/Contacts"));
const AsyncContentLibraries = lazy(() => import("../Dashboard/pages/ContentLibraries/ContentLibraries"));
const AsyncDashboard = lazy(() => import("../Dashboard/pages/Dashboard/Dashboard"));
const AsyncDoesNotExist = lazy(() => import("../Dashboard/pages/DoesNotExist/DoesNotExist"));
const AsyncExperience = lazy(() => import("../VisualBuilder"));
const AsyncIconLibrary = lazy(() => import("../Dashboard/pages/IconLibrary/IconLibrary"));
const AsyncMediaViewer = lazy(() => import("../VisualBuilder/components/MediaViewer/MediaViewer"));
const AsyncMyAccountFeatureFlagWrapper = lazy(() => import("../Dashboard/pages/MyAccount/MyAccountFeatureFlagWrapper"));
const AsyncOrganizationProperties = lazy(
  () => import("../Dashboard/pages/Organizations/OrganizationProperties/OrganizationProperties"),
);
const AsyncOrganizationExperiences = lazy(() => import("../Dashboard/pages/ExperiencesV2/ExperiencesV2"));
const AsyncPropertiesCreate = lazy(() => import("../Dashboard/pages/CreateProperty/CreateProperty"));
const AsyncProperties = lazy(() => import("../Dashboard/pages/Properties/Properties"));
const AsyncPropertyCoaches = lazy(() => import("../Dashboard/pages/Coaches/Coaches"));
const AsyncPropertyMembers = lazy(() => import("../Dashboard/pages/Members/Members"));
const AsyncPropertySettings = lazy(() => import("../Dashboard/pages/PropertySettings/PropertySettings"));
const AsyncSignIn = lazy(() => import("../Dashboard/pages/SignIn/SignIn"));
const AsyncUniversity = lazy(() => import("../Dashboard/pages/University/University"));
const AsyncUserProfile = lazy(() => import("../Dashboard/pages/UserProfile/UserProfile"));

const publicRoutes = [
  {
    element: <AsyncMediaViewer />,
    path: PUBLIC_ROUTE.PROPERTY.ASSET_LIBRARY.VIEW,
  },
  {
    element: <AsyncExperience publicPage={true} />,
    path: PUBLIC_ROUTE.PROPERTY.EXPERIENCES.VIEW,
  },
  {
    element: <AsyncExperience publicPage={true} />,
    path: PUBLIC_ROUTE.PROPERTY.EXPERIENCES.ALL,
  },
  {
    element: <AsyncExperience publicPage={true} />,
    path: PUBLIC_ROUTE.PROPERTY.EXPERIENCES.SINGLE,
  },
  {
    element: <AsyncDoesNotExist />,
    path: PUBLIC_ROUTE.NOT_FOUND,
  },
];

const dashboardRoutes: RouteObject[] = [
  {
    element: <DashboardLayout />,
    errorElement: (
      <AsyncDoesNotExist
        message="An unexpected error occurred. Please log out and try again later. Please contact support if the problem persists."
        returnPrev={false}
      />
    ),
    loader: dashboardLoader,
    children: [
      {
        element: <AuthenticationGuard component={AsyncDashboard} />,
        path: DASHBOARD_ROUTE.HOME,
      },
      {
        element: <AnalyticsLayout />,
        loader: analyticsLoader,
        shouldRevalidate: shouldRevalidateAnalytics,
        children: [
          {
            element: <AuthenticationGuard component={AsyncAnalyticsOverview} />,
            path: DASHBOARD_ROUTE.ANALYTICS.LIST,
          },
          {
            element: (
              <AuthenticationGuard component={AnalyticsOverviewCard} allowedPermissions={["analytics_admin:read"]} />
            ),
            path: DASHBOARD_ROUTE.ANALYTICS.VIEW,
          },
        ],
      },
      {
        element: <DropZoneLayout />,
        children: [
          {
            element: <AuthenticationGuard component={AsyncAssetLibrary} allowedPermissions={["media_items:read"]} />,
            path: DASHBOARD_ROUTE.ASSET_LIBRARY.LIST,
          },
          {
            element: (
              <AuthenticationGuard component={AsyncIconLibrary} allowedPermissions={["icons:create", "icons:update"]} />
            ),
            path: DASHBOARD_ROUTE.SETTINGS.ICONS,
          },
        ],
      },
      {
        element: <AuthenticationGuard component={AsyncComingSoon} />,
        path: DASHBOARD_ROUTE.COLLECTIONS.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncContacts} />,
        path: DASHBOARD_ROUTE.CONTACTS.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncContactDetails} />,
        path: DASHBOARD_ROUTE.CONTACTS.VIEW,
      },
      {
        path: DASHBOARD_ROUTE.SETTINGS.VIEW,
        element: <AuthenticationGuard component={AsyncPropertySettings} allowedPermissions={["properties:update"]} />,
      },
      {
        path: DASHBOARD_ROUTE.UNIVERSITY.LIST,
        element: <AuthenticationGuard component={AsyncUniversity} allowedPermissions={["universities:read"]} />,
      },
      {
        path: DASHBOARD_ROUTE.ACCOUNTS.LIST,
        element: <AuthenticationGuard component={AsyncAccounts} allowedPermissions={["organizations:read"]} />,
      },
      {
        path: DASHBOARD_ROUTE.ACCOUNTS.ACTIVATE,
        element: (
          <AuthenticationGuard component={AsyncActivateAccountPage} allowedPermissions={["organizations:update"]} />
        ),
        loader: currentOrganizationLoader,
      },
      {
        path: DASHBOARD_ROUTE.ACCOUNTS.MANAGE,
        element: (
          <AuthenticationGuard component={AsyncManageAccountPage} allowedPermissions={["organizations:update"]} />
        ),
        loader: currentOrganizationLoader,
      },
      {
        path: ORGANIZATIONS.EXPERIENCES.LIST,
        element: (
          <AuthenticationGuard
            component={AsyncOrganizationExperiences}
            allowedPermissions={["org_experiences:create"]}
          />
        ),
        loader: currentOrganizationLoader,
      },
      {
        path: ORGANIZATIONS.USERS.LIST,
        element: <AuthenticationGuard component={AsyncComingSoon} allowedPermissions={["org_users:create"]} />,
      },
      {
        path: ORGANIZATIONS.PROPERTIES.LIST,
        element: (
          <AuthenticationGuard component={AsyncOrganizationProperties} allowedPermissions={["org_properties:read"]} />
        ),
      },
      {
        path: ORGANIZATIONS.ASSET_LIBRARY.LIST,
        element: <AuthenticationGuard component={AsyncComingSoon} allowedPermissions={["org_media_items:read"]} />,
      },
      { path: DASHBOARD_ROUTE.USERS.LIST, element: <AuthenticationGuard component={AsyncPropertyMembers} /> },
      { path: DASHBOARD_ROUTE.USERS.VIEW, element: <AuthenticationGuard component={AsyncUserProfile} /> },
      {
        path: DASHBOARD_ROUTE.EXPERIENCES.LIST,
        element: <AuthenticationGuard component={AsyncExperienceFeatureFlagWrapper} />,
        loader: experiencesLoader,
      },
      {
        path: DASHBOARD_ROUTE.PROFILE,
        element: <AuthenticationGuard component={AsyncMyAccountFeatureFlagWrapper} />,
      },
    ],
  },
];

const adminRoutes: RouteObject[] = [
  {
    element: <DashboardLayout />,
    loader: dashboardLoader,
    children: [
      {
        element: <AuthenticationGuard component={AsyncProperties} />,
        path: ADMIN_ROUTE.PROPERTIES.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncPropertiesCreate} allowedPermissions={["properties:create"]} />,
        path: ADMIN_ROUTE.PROPERTIES.ADD,
      },
      {
        element: (
          <AuthenticationGuard
            component={AsyncContentLibraries}
            allowedPermissions={["media_items:create", "media_items:update"]}
          />
        ),
        path: ADMIN_ROUTE.ASSET_LIBRARIES.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncContentLibraries} />,
        path: ADMIN_ROUTE.ASSET_LIBRARIES.VIEW,
      },
      {
        element: <AuthenticationGuard component={AsyncPropertyCoaches} allowedPermissions={["coaches:create"]} />,
        path: ADMIN_ROUTE.COACHES.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncUserProfile} />,
        path: ADMIN_ROUTE.COACHES.VIEW,
      },
      {
        element: (
          <AuthenticationGuard component={AsyncAllUsers} allowedPermissions={["users:create", "users:delete"]} />
        ),
        path: ADMIN_ROUTE.USERS.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncUserProfile} />,
        path: ADMIN_ROUTE.USERS.VIEW,
      },
      {
        element: <AuthenticationGuard component={AsyncUniversity} allowedPermissions={["universities:read"]} />,
        path: ADMIN_ROUTE.UNIVERSITY.LIST,
      },
      {
        element: <AuthenticationGuard component={AsyncUniversity} allowedPermissions={["universities:read"]} />,
        path: ADMIN_ROUTE.UNIVERSITY.VIEW,
      },
    ],
  },
];

const visualBuilderRoutes: RouteObject[] = [
  {
    element: <VisualBuilderLayout />,
    loader: dashboardLoader,
    children: [
      {
        element: <AuthenticationGuard component={AsyncExperience} />,
        loader: visualBuilderLoader,
        path: VISUAL_BUILDER_ROUTE.HOME,
      },
      {
        element: <AuthenticationGuard component={AsyncExperience} />,
        path: VISUAL_BUILDER_ROUTE.EXPERIENCES.COLLECT,
      },
      {
        element: <AuthenticationGuard component={AsyncExperience} />,
        path: VISUAL_BUILDER_ROUTE.EXPERIENCES.CUSTOMIZE,
      },
      {
        element: <AuthenticationGuard component={AsyncExperience} allowedPermissions={["org_experiences:create"]} />,
        path: ORGANIZATIONS.VISUAL_BUILDER.HOMEBASE,
      },
      {
        element: <AuthenticationGuard component={AsyncExperience} allowedPermissions={["org_experiences:create"]} />,
        path: ORGANIZATIONS.VISUAL_BUILDER.EXPERIENCES.COLLECT,
      },
      {
        element: <AuthenticationGuard component={AsyncExperience} allowedPermissions={["org_experiences:create"]} />,
        path: ORGANIZATIONS.VISUAL_BUILDER.EXPERIENCES.CUSTOMIZE,
      },
    ],
  },
];

const authenticatedRoutes: RouteObject[] = [
  {
    element: <Auth0ProviderLayout />,
    children: [
      {
        element: <AsyncSignIn />,
        path: PUBLIC_ROUTE.SIGN_IN,
      },
      ...dashboardRoutes,
      ...adminRoutes,
      ...visualBuilderRoutes,
    ],
  },
];

export const routesConfig: RouteObject[] = [
  {
    element: <RootLayout />,
    children: [...publicRoutes, ...authenticatedRoutes],
  },
];
