import { useAuth0 } from "@auth0/auth0-react";
import { Box, useTheme } from "@mui/material";
import { type JwtPayload, jwtDecode } from "jwt-decode";
import { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { type LoaderFunctionArgs, Outlet, useLocation, useNavigate } from "react-router-dom";

import DoesNotExist from "@/Dashboard/pages/DoesNotExist/DoesNotExist";
import ErrorBoundary from "@/containers/ErrorBoundary/ErrorBoundary";
import LoadingSplash from "@/containers/LoadingComponent/LoadingSplash";
import ToastElement from "@/containers/Toast/ToastElement";
import { useFlagsData } from "@/hooks";
import { DASHBOARD_ROUTE, PUBLIC_ROUTE } from "@/routes/constants";
import { api } from "@/services/instance";
import store from "@/store";
import { getAuthorization, selectAuthStatus, selectUser, setStatus, setUser } from "@/store/auth/auth.slice";
import { useThunkDispatch, useThunkSelector } from "@/store/hooks";
import { flagsSelector } from "@/store/selectors";
import { fetchCurrentOrganization } from "@/store/v2/currentOrganization/currentOrganizationSlice";
import { getAccessToken } from "@/utils/auth";

const AsyncMainHeader = lazy(() => import("../VisualBuilder/containers/MainHeader"));
const AsyncOrganizationsMainHeader = lazy(() => import("../VisualBuilder/containers/OrganizationsMainHeader"));

const AsyncSidebar = lazy(() => import("../Dashboard/components/Sidebar/Sidebar"));
const AsyncOrganizationsSidebar = lazy(
  () => import("../Dashboard/components/OrganizationsSidebar/OrganizationsSidebar"),
);

export const dashboardLoader = async ({ params }: LoaderFunctionArgs) => {
  const accessToken = getAccessToken();
  const { propertyId, organizationId } = params;

  try {
    await store.dispatch(getAuthorization({ authenticationToken: accessToken, propertyId: Number(propertyId) }));
    const response = await api(`/api/v2/users/${store.getState().auth?.user.id}`);

    await store.dispatch(setUser(response.data));
    await store.dispatch(setStatus("success"));

    if (organizationId) {
      await store.dispatch(fetchCurrentOrganization(Number(organizationId)));
    }

    return store.getState().auth?.user;
    // eslint-disable-next-line sonarjs/no-ignored-exceptions, @typescript-eslint/no-unused-vars
  } catch (error) {
    return null;
  }
};

const DashboardLayout = () => {
  const user = useThunkSelector(selectUser);
  const authStatus = useThunkSelector(selectAuthStatus);
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const tokenExpirationInterval = 900_000; // 15 minutes
  const [tokenExpiration, setTokenExpiration] = useState<number | null>(null);
  const dispatch = useThunkDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { flags } = useSelector(flagsSelector);

  const isNewProfilePage =
    location.pathname.includes(DASHBOARD_ROUTE.PROFILE) && flags && flags["digital-business-card"];

  useFlagsData({ userId: user?.id });

  useEffect(() => {
    const tokenExpiry = async () => {
      const token = await getAccessTokenSilently();
      const decoded = jwtDecode<JwtPayload>(token);
      if (decoded && decoded.exp) {
        setTokenExpiration(decoded.exp * 1000);
      }
    };

    tokenExpiry();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (tokenExpiration && tokenExpiration < Date.now()) {
        dispatch(setStatus("expired"));
      }
    };

    const checkExpiration = setInterval(() => {
      checkTokenExpiry();
    }, tokenExpirationInterval);

    return () => clearInterval(checkExpiration);
  }, [tokenExpiration]);

  useEffect(() => {
    if ((!isLoading && !isAuthenticated) || error) {
      navigate(PUBLIC_ROUTE.SIGN_IN);
    }
  }, [isLoading, isAuthenticated, error]);

  if (authStatus === "expired") {
    return <DoesNotExist returnPrev={false} message="Oops, Your session has expired" />;
  }

  if (isAuthenticated && authStatus === "error") {
    return <DoesNotExist returnPrev={false} message="Oops, You need to be invited first" />;
  }

  const isOrganizationsSection = location.pathname.startsWith("/organizations");

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSplash />}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.common.white,
          }}
          data-testid="AppLayout"
        >
          {isOrganizationsSection ? <AsyncOrganizationsMainHeader /> : <AsyncMainHeader />}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              zIndex: 1,
              display: "flex",
              width: "100%",
              flexDirection: "row",
              overflow: "inherit",
              backgroundColor: theme.palette.common.white,
              borderTop: `40px solid ${theme.palette.common.black}`,
              height: "100%",
              position: "absolute",
            }}
          >
            {!isNewProfilePage && (isOrganizationsSection ? <AsyncOrganizationsSidebar /> : <AsyncSidebar />)}
            <ErrorBoundary>
              <Suspense>
                <Box
                  sx={{
                    overflow: "hidden",
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                    "@media (max-width:576px)": {
                      overflow: "auto",
                    },
                  }}
                >
                  <Outlet />
                </Box>
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Box>
        <ToastElement />
      </Suspense>
    </ErrorBoundary>
  );
};

export default DashboardLayout;
