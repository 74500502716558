import { type LoaderFunctionArgs, Outlet, type ShouldRevalidateFunctionArgs } from "react-router-dom";

import AnalyticsOverviewHeader from "@/Dashboard/components/AnalyticsOverview/Header/AnalyticsOverviewHeader";
import type { AnalyticViews } from "@/Dashboard/components/AnalyticsOverview/types";
import type { PropertyTier } from "@/Dashboard/pages/AssetLibrary/types";
import type { PropertyInterface } from "@/Dashboard/pages/PropertySettings/types";
import { PROPERTY_TIERS } from "@/constants";
import store from "@/store";
import type { User } from "@/store/v2/currentUser/selectors";
import { waitForAuthReady } from "@/utils/waitForAuthReady";

export async function analyticsLoader({ params }: LoaderFunctionArgs) {
  return waitForAuthReady(async () => {
    const { cardId } = params;
    const property = store.getState().currentProperty?.property as PropertyInterface;
    const user = store.getState().auth?.user as User;
    const isSalesperson = user?.permissions?.includes("analytics_admin:read") === false;
    const isProTier = (PROPERTY_TIERS.PRO as PropertyTier) === property?.tier?.title;
    const analyticsView = { data: { limitedAnalyticsView: "overview" } } as {
      data: { limitedAnalyticsView: AnalyticViews };
    };

    if (isProTier && (isSalesperson || cardId === "experience")) {
      analyticsView.data.limitedAnalyticsView = "experience";
    } else if (!isProTier || cardId === "asset") {
      analyticsView.data.limitedAnalyticsView = "asset";
    } else if (cardId === "channel") {
      analyticsView.data.limitedAnalyticsView = "channel";
    } else {
      analyticsView.data.limitedAnalyticsView = "overview";
    }

    return analyticsView;
  });
}

// when navigating the analyticsLoader does not invoke so this forces the analyticsView to be updated for analytics Loader
export function shouldRevalidateAnalytics({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs) {
  return currentUrl.pathname !== nextUrl.pathname;
}

const AnalyticsLayout = () => (
  <>
    <AnalyticsOverviewHeader />
    <Outlet />
  </>
);

export default AnalyticsLayout;
