import type { AxiosError } from "axios";

import type { OrgPropData } from "@/Dashboard/pages/Accounts/types";
import type { Query } from "@/store/v2/queries/types";

import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

interface UpdateOrganizationData {
  displayName?: string;
  linkedProperties?: OrgPropData[];
  active?: boolean;
  accountAdmin?: {
    name?: string;
    email?: string;
  };
}

interface CreateOrganizationData {
  displayName: string;
  salesforceName: string;
  safeId: string;
}

interface CreateOrganizationSuccess {
  resp: { id: number };
  err: null;
  status: "succeeded";
}

interface CreateOrganizationError {
  resp: null;
  err: {
    status: number;
    message?: string | AxiosError;
  };
  status: "failed";
}

type CreateOrganizationResponse = CreateOrganizationSuccess | CreateOrganizationError;

export class OrganizationsService {
  static async getOrganizations(query: Query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getOrganizationById(organizationId: number) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async createOrganization(data: CreateOrganizationData): Promise<CreateOrganizationResponse> {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/organizations`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updateOrganization(organizationId: number, data: UpdateOrganizationData) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/organizations/${organizationId}`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async validateHCAccountEmail(email: string) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/user/validate-email`,
        params: { email },
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getLinkedProperties(organizationId: number, query: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}/properties`,
        params: query,
      });

      return {
        resp: {
          data: resp.data?.data ?? [],
          count: resp.data?.count ?? 0,
        },
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  /**
   * Retrieves the experiences of properties linked to an organization.
   */
  static async getExperiences(organizationId: number, query?: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}/experiences`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  /**
   * Retrieves all account admins of an organization.
   */
  static async getUsers(organizationId: number, query?: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}/users`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  /**
   * Retrieves assets of all linked properties in an organization.
   */
  static async getAssets(organizationId: number, query?: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}/assets`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  /**
   * Retrieves asset categories with tags and members for all linked properties in an organization.
   */
  static async getAssetCategories(organizationId: number) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}/asset-categories`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
