export const EXPERIENCE_STATUS = {
  DRAFT: "draft",
  SHARED: "shared",
  SINGLE_SHARE: "single-share",
} as const;

export type EXPERIENCE_STATUS = (typeof EXPERIENCE_STATUS)[keyof typeof EXPERIENCE_STATUS];

export const EXPERIENCE_ACTIVITY = {
  UNOPENED: "unopened",
  OPENED: "opened",
} as const;

export const EXPERIENCE_PRIMARY_FILTER = {
  TYPE: "type",
  TAG: "tag",
} as const;

export const EXPERIENCE_OPTIONS = {
  RENAME: "rename",
  VIEW: "view",
  EDIT: "edit",
  CONVERT_TO_TEMPLATE: "convert_to_template",
  SHARE: "share",
  COPY_SHARE_LINK: "copy_share_link",
  DUPLICATE: "duplicate",
  FAVORITE: "favorite",
  DELETE: "delete",
  SAVE_AS_EXPERIENCE: "save_as_experience",
  CANCEL: "cancel",
  CREATE_FROM_TEMPLATE: "create_from_template",
  CREATE_FROM_EXPERIENCE: "create_from_experience",
  CREATE_EMBED_INTEGRATION: "create_embed_integration",
} as const;

export const EXPERIENCE_OPTIONS_V2 = {
  RENAME: "rename",
  EDIT: "edit",
  CONVERT_TO_TEMPLATE: "convert_to_template",
  SHARE: "share",
  DUPLICATE: "duplicate",
  DELETE: "delete",
  SAVE_AS_EXPERIENCE: "save_as_experience",
  CREATE_EMBED_INTEGRATION: "create_embed_integration",
} as const;

export type EXPERIENCE_OPTIONS_V2 = (typeof EXPERIENCE_OPTIONS_V2)[keyof typeof EXPERIENCE_OPTIONS_V2];

export const EXPERIENCE_FILTER_STATUS = {
  DRAFT: "draft",
  SHARED: "shared",
  LOCKED: "locked",
  UNLOCKED: "unlocked",
} as const;

export type EXPERIENCE_FILTER_STATUS = (typeof EXPERIENCE_FILTER_STATUS)[keyof typeof EXPERIENCE_FILTER_STATUS];

export const EXPERIENCE_CATEGORY = {
  MY_EXPERIENCES: "myExperiences",
  ALL_EXPERIENCES: "allExperiences",
  TEMPLATES: "templates",
  PROPERTY_FULL_COLLECTIONS: "propertyFullCollections",
} as const;

export type EXPERIENCE_CATEGORY = (typeof EXPERIENCE_CATEGORY)[keyof typeof EXPERIENCE_CATEGORY];
