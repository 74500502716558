export const PROPERTY_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export const PROPERTY_TIERS = {
  PROVISIONAL: "truetour",
  PRO: "saleshub_pro",
  STANDARD: "saleshub_starter",
  CONNECT: "connect",
} as const;

export type PROPERTY_TIERS = (typeof PROPERTY_TIERS)[keyof typeof PROPERTY_TIERS];

export const PROPERTY_TIER_NAMES = {
  truetour: "TrueTour",
  saleshub_pro: "SalesHub Pro",
  saleshub_starter: "SalesHub Starter",
  connect: "Connect",
};
